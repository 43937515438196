@media (min-width: 768px) {
     .LinkNav__link::before {
          content: '';
          display: block;
          height: 2px;
          width: 0;
          background-color: rgb(177, 249, 132);
          transition: width 0.3s ease-in-out;
     }

     .LinkNav__link:focus::before {
          width: 100%;
     }
}